import React, { useState, useEffect } from 'react';
import { List, Button, Icon, Input, Popup, Message, Header } from 'semantic-ui-react';
import { fireStore } from '../firebase';
import { collection, query, where, getDocs, updateDoc, doc, deleteDoc, getDoc } from 'firebase/firestore';
import styled from 'styled-components';
import { auth } from '../firebase';

const FileListContainer = styled.div`
  margin-top: 0.8em;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0.5em;
  background: #f9f9f9;
  max-height: 350px;
  overflow-y: auto;
`;

const FileItem = styled(List.Item)`
  display: flex !important;
  align-items: center !important;
  padding: 0.4em !important;
  margin-bottom: 0.2em !important;
  border-radius: 3px;
  
  &:hover {
    background: #f0f0f0;
  }
  
  &.active {
    background: #e6f7ff;
    border-left: 3px solid #1890ff;
    padding-left: 0.3em !important;
  }
`;

const FileName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 160px;
  font-weight: 500;
`;

const FileDate = styled.div`
  font-size: 0.8em;
  color: #666;
`;

const FileActions = styled.div`
  margin-left: auto;
  display: flex;
  gap: 3px;
`;

export default function FileList({ currentUser, onFileSelect, onFilesLoaded }) {
  const [files, setFiles] = useState([]);
  const [editingFile, setEditingFile] = useState(null);
  const [newFileName, setNewFileName] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [loading, setLoading] = useState(true);
  const [activeFileId, setActiveFileId] = useState(null);

  const loadFiles = async () => {
    if (!currentUser) return;
    
    setLoading(true);
    const filesRef = collection(fireStore, 'files');
    const q = query(filesRef, where('userId', '==', currentUser.uid));
    
    try {
      const querySnapshot = await getDocs(q);
      const filesList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      setFiles(filesList);
      
      // Notify parent component about file existence
      if (onFilesLoaded) {
        onFilesLoaded(filesList.length > 0);
      }
    } catch (error) {
      console.error('Error loading files:', error);
      setMessage('Dosyalar yüklenirken bir hata oluştu');
      setMessageType('error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadFiles();
  }, [currentUser]);

  const handleRename = async (fileId) => {
    if (!newFileName.trim()) return;
    
    try {
      const fileRef = doc(fireStore, 'files', fileId);
      await updateDoc(fileRef, {
        fileName: newFileName
      });
      
      setFiles(files.map(file => 
        file.id === fileId ? { ...file, fileName: newFileName } : file
      ));
      setEditingFile(null);
      setNewFileName('');
      setMessage('Dosya başarıyla yeniden adlandırıldı');
      setMessageType('success');
    } catch (error) {
      console.error('Error renaming file:', error);
      setMessage('Dosya yeniden adlandırılırken hata oluştu');
      setMessageType('error');
    }
  };

  const handleDelete = async (fileId) => {
    try {
      // Get the file reference from Firestore
      const fileRef = doc(fireStore, 'files', fileId);
      const fileDoc = await getDoc(fileRef);
      
      if (!fileDoc.exists()) {
        throw new Error('Dosya bulunamadı');
      }

      const fileData = fileDoc.data();
      
      // Check if user has permission
      if (fileData.userId !== auth.currentUser.uid) {
        throw new Error('Bu dosyayı silme yetkiniz yok');
      }

      // Delete from Firestore
      await deleteDoc(fileRef);

      // Update UI
      const updatedFiles = files.filter(file => file.id !== fileId);
      setFiles(updatedFiles);
      
      // Notify parent about files status after deletion
      if (onFilesLoaded) {
        onFilesLoaded(updatedFiles.length > 0);
      }
      
      if (activeFileId === fileId) {
        setActiveFileId(null);
      }
      
      // Show success message
      setMessage('Dosya başarıyla silindi');
      setMessageType('success');
    } catch (error) {
      console.error('Error deleting file:', error);
      setMessage(error.message || 'Dosya silinirken bir hata oluştu');
      setMessageType('error');
    }
  };

  const handleFileClick = (file) => {
    setActiveFileId(file.id);
    onFileSelect(file);
  };

  return (
    <div>
      <Header as="h3" style={{ marginTop: '1.2em', marginBottom: '0.5em' }}>Yüklenen Dosyalar</Header>
      
      {message && (
        <Message
          positive={messageType === 'success'}
          negative={messageType === 'error'}
          content={message}
          size="tiny"
          onDismiss={() => setMessage('')}
        />
      )}
      
      <FileListContainer>
        {loading ? (
          <Message info size="tiny" content="Dosyalar yükleniyor..." />
        ) : files.length === 0 ? (
          <Message info size="tiny" content="Henüz yüklenmiş dosya bulunmamaktadır." />
        ) : (
          <List divided relaxed>
            {files.map(file => (
              <FileItem 
                key={file.id} 
                className={activeFileId === file.id ? 'active' : ''}
                onClick={() => handleFileClick(file)}
              >
                <Icon name={file.fileType === 'csv' ? 'file text outline' : 'file excel outline'} />
                {editingFile === file.id ? (
                  <Input
                    size="mini"
                    value={newFileName}
                    onChange={(e) => setNewFileName(e.target.value)}
                    onBlur={() => handleRename(file.id)}
                    onKeyPress={(e) => e.key === 'Enter' && handleRename(file.id)}
                    autoFocus
                    style={{ width: '150px' }}
                  />
                ) : (
                  <div>
                    <FileName>{file.fileName}</FileName>
                    <FileDate>
                      {file.uploadedAt?.toDate ? 
                        new Date(file.uploadedAt.toDate()).toLocaleDateString('tr-TR') : 
                        'Tarih bilgisi yok'}
                    </FileDate>
                  </div>
                )}
                <FileActions onClick={e => e.stopPropagation()}>
                  <Popup
                    trigger={
                      <Button icon size="mini" onClick={() => {
                        setEditingFile(file.id);
                        setNewFileName(file.fileName);
                      }}>
                        <Icon name="edit" />
                      </Button>
                    }
                    content="Yeniden Adlandır"
                    position="top center"
                    size="tiny"
                  />
                  <Popup
                    trigger={
                      <Button icon size="mini" color="red" onClick={() => handleDelete(file.id)}>
                        <Icon name="trash" />
                      </Button>
                    }
                    content="Sil"
                    position="top center"
                    size="tiny"
                  />
                </FileActions>
              </FileItem>
            ))}
          </List>
        )}
      </FileListContainer>
    </div>
  );
} 