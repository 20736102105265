import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Form, Button, Message, Container, Header } from 'semantic-ui-react';

export default function AdminLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError('');
      setLoading(true);
      await login(email, password);
      history.push('/admin/dashboard');
    } catch (error) {
      setError('Giriş başarısız: Lütfen email ve şifrenizi kontrol edin');
    }
    setLoading(false);
  }

  return (
    <Container text style={{ marginTop: '7em' }}>
      <Header as='h2' textAlign='center'>Yönetici Girişi</Header>
      {error && <Message negative>{error}</Message>}
      <Form onSubmit={handleSubmit}>
        <Form.Field>
          <label>E-posta</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="E-posta adresiniz"
          />
        </Form.Field>
        <Form.Field>
          <label>Şifre</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            placeholder="Şifreniz"
          />
        </Form.Field>
        <Button 
          primary 
          type="submit" 
          disabled={loading}
          fluid
        >
          Giriş Yap
        </Button>
      </Form>
    </Container>
  );
} 