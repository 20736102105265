// import firebase from 'firebase/app'
// import 'firebase/firestore'
// import "firebase/auth";
// import "firebase/storage";

// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_apiKey,
//   authDomain: process.env.REACT_APP_authDomain,
//   projectId: process.env.REACT_APP_projectId,
//   storageBucket: process.env.REACT_APP_storageBucket,
//   messagingSenderId: process.env.REACT_APP_messagingSenderId,
//   appId: process.env.REACT_APP_appId,
//   measurementId: process.env.REACT_APP_appId,
//   databaseURL: process.env.REACT_APP_databaseURL
// };

// const app = firebase.initializeApp(firebaseConfig);
// export const auth = app.auth();
// export const store = app.firestore();
// export const storage = app.storage();
// // export default firebase;

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCVbUDLcG15QrjbXQOvXnw08XCgMhbxj5I",
  authDomain: "akka-demir-celik.firebaseapp.com",
  projectId: "akka-demir-celik",
  storageBucket: "akka-demir-celik.appspot.com",
  messagingSenderId: "559696887675",
  appId: "1:559696887675:web:669a933905d1607eacc7de",
  measurementId: "G-SV80EPQPC6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const fireStore = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);


