import React from 'react';
import { Progress } from 'semantic-ui-react';
import styled from 'styled-components';

const ProgressContainer = styled.div`
  margin: 1em 0;
  padding: 1em;
  background: #f8f9fa;
  border-radius: 4px;
  border: 1px solid #e9ecef;
`;

const StatusText = styled.div`
  margin-bottom: 0.5em;
  color: #495057;
  font-size: 0.9em;
`;

export default function UploadProgress({ progress, status }) {
  return (
    <ProgressContainer>
      <StatusText>{status}</StatusText>
      <Progress 
        percent={Math.round(progress)} 
        indicating 
        size="small"
        color={progress === 100 ? 'green' : 'blue'}
        active={progress < 100}
      />
    </ProgressContainer>
  );
} 