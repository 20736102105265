import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Container, Header, Message } from 'semantic-ui-react';
import styled from 'styled-components';

// Register the required chart components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ChartContainer = styled.div`
  margin-top: 1em;
  padding: 1.5em;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  width: 100%;
  max-width: 800px;
  height: 500px;
`;

const ChartWrapper = styled.div`
  height: 400px;
  position: relative;
`;

const ChartTitle = styled(Header)`
  margin-bottom: 1.5em !important;
  text-align: center;
  color: #333;
`;

export default function RevenueChart({ data }) {
  if (!data || !Array.isArray(data) || data.length === 0) {
    return (
      <Container>
        <Message info>
          <Message.Header>Veri bulunamadı</Message.Header>
          <p>Lütfen revenue dosyasını yükleyin.</p>
        </Message>
      </Container>
    );
  }

  // Group data by client and sum revenue
  const clientData = data.reduce((acc, row) => {
    const clientName = row['Cari hesap adı'];
    const revenue = parseFloat(row['Ciro']) || 0;
    
    if (clientName && !isNaN(revenue)) {
      if (!acc[clientName]) {
        acc[clientName] = 0;
      }
      acc[clientName] += revenue;
    }
    return acc;
  }, {});

  // Sort clients by revenue (descending) and get top 5
  const sortedClients = Object.entries(clientData)
    .sort((a, b) => b[1] - a[1])
    .slice(0, 5);

  const chartData = {
    labels: sortedClients.map(([client]) => {
      // Truncate long client names
      return client.length > 20 ? client.substring(0, 20) + '...' : client;
    }),
    datasets: [
      {
        label: 'Ciro (₺)',
        data: sortedClients.map(([, revenue]) => revenue),
        backgroundColor: [
          'rgba(54, 162, 235, 0.6)',
          'rgba(75, 192, 192, 0.6)',
          'rgba(255, 206, 86, 0.6)',
          'rgba(153, 102, 255, 0.6)',
          'rgba(255, 159, 64, 0.6)',
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false, // Hide built-in title as we use our own Header
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            const value = context.parsed.y;
            label += new Intl.NumberFormat('tr-TR', {
              style: 'currency',
              currency: 'TRY',
              minimumFractionDigits: 2
            }).format(value);
            return label;
          }
        }
      },
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function(value) {
            return new Intl.NumberFormat('tr-TR', {
              style: 'currency',
              currency: 'TRY',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            }).format(value);
          },
        },
      },
      x: {
        ticks: {
          font: {
            size: 11
          }
        }
      }
    },
  };

  return (
    <ChartContainer>
      <ChartTitle as="h2">Ciro Analizi - En Yüksek Cirolu 5 Müşteri</ChartTitle>
      <ChartWrapper>
        <Bar data={chartData} options={chartOptions} />
      </ChartWrapper>
    </ChartContainer>
  );
} 