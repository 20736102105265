import React from 'react';
import { Link } from 'react-router-dom';
import {
    Container,
    Grid,
    Header,
    List,
    Segment,
    Button,
} from 'semantic-ui-react';

const Footer = () => {
    return (
        <Segment  vertical style={{ padding: '5em 0em', backgroundColor: '#0A1F30' }}>
            <Container>
                <Grid divided inverted stackable>
                <Grid.Row>
                    <Grid.Column width={3}>
                    <Header inverted as='h4' content='Hakkimizda' />
                    <List link inverted>
                        <List.Item as='a'>Site Haritasi</List.Item>
                        <List.Item as='a'>Iletisim</List.Item>
                        <List.Item as='a'>Hizmetler</List.Item>
                        <List.Item as='a'>Takim</List.Item>
                    </List>
                    </Grid.Column>
                    <Grid.Column width={3}>
                    <Header inverted as='h4' content='Hizmetler' />
                    <List link inverted>
                        <List.Item as='a'>Lazer Kesim</List.Item>
                        <List.Item as='a'>Abkant Bukum</List.Item>
                        <List.Item as='a'>Kaynakli Imalat</List.Item>
                        <List.Item as='a'>Tasatim</List.Item>
                        <List.Item as='a'>Montaj</List.Item>
                        <List.Item as='a'>Hammadde Tedarik</List.Item>
                    </List>
                    </Grid.Column>
                    <Grid.Column width={7}>
                    <Header as='h4' inverted>
                        Akka Demir Çelik
                    </Header>
                    <p style={{color:"white"}}>
                        Size en iyi hizmeti ve ürünü vermek için buradayız
                    </p>
                    <Link to="/admin/login">
                        <Button 
                            size='tiny' 
                            inverted 
                            basic 
                            style={{ 
                                marginTop: '1em',
                                opacity: 0.7,
                                fontSize: '0.8em'
                            }}
                        >
                            Yönetici Girişi
                        </Button>
                    </Link>
                    </Grid.Column>
                </Grid.Row>
                </Grid>
            </Container>
        </Segment>
    )
}

export default Footer
