import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Container, Header, Button, Grid, Form, Message } from 'semantic-ui-react';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import RevenueChart from '../components/RevenueChart';
import FileList from '../components/FileList';
import UploadProgress from '../components/UploadProgress';
import { fireStore, storage } from '../firebase';
import { collection, addDoc, deleteDoc, doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import styled from 'styled-components';

const CustomFileInput = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;

  input[type="file"] {
    position: absolute;
    left: -9999px;
  }

  label {
    display: inline-block;
    padding: 8px 12px;
    background: #e0e1e2;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;

    &:hover {
      background: #cacbcd;
    }
  }

  span {
    color: #666;
  }
`;

const DashboardLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 100px);
  padding: 0 20px;
`;

const MainContent = styled.div`
  display: flex;
  flex: 1;
  gap: 30px;
  margin-top: 20px;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SidebarColumn = styled.div`
  flex: 0 0 320px;
  
  @media (max-width: 768px) {
    flex: 1 0 auto;
  }
`;

const ContentColumn = styled.div`
  flex: 1;
  max-width: 800px;
  margin: 0 auto;
`;

const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;

const UserInfo = styled.div`
  flex: 1;
`;

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
const ALLOWED_EXTENSIONS = ['csv', 'xls', 'xlsx'];
const MAX_RETRY_ATTEMPTS = 3;

export default function AdminDashboard() {
  const { currentUser, logout } = useAuth();
  const history = useHistory();
  const [revenueData, setRevenueData] = useState(null);
  const [error, setError] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState('');
  const [retryCount, setRetryCount] = useState(0);
  const [uploadError, setUploadError] = useState('');
  const [hasUploadedFiles, setHasUploadedFiles] = useState(false);

  async function handleLogout() {
    try {
      await logout();
      history.push('/admin/login');
    } catch (error) {
      console.error('Çıkış yapılırken hata oluştu:', error);
    }
  }

  const validateFile = (file) => {
    if (!file) throw new Error('Lütfen bir dosya seçin');
    
    const extension = file.name.split('.').pop().toLowerCase();
    if (!ALLOWED_EXTENSIONS.includes(extension)) {
      throw new Error(`Desteklenmeyen dosya formatı. Desteklenen formatlar: ${ALLOWED_EXTENSIONS.join(', ')}`);
    }

    if (file.size > MAX_FILE_SIZE) {
      throw new Error('Dosya boyutu çok büyük. Maksimum dosya boyutu: 10MB');
    }

    return true;
  };

  const processData = useCallback((data) => {
    try {
      if (!Array.isArray(data) || data.length === 0) {
        throw new Error('Geçersiz veri formatı');
      }

      const requiredColumns = ['Cari hesap adı', 'Ciro'];
      const hasRequiredColumns = requiredColumns.every(col => 
        Object.keys(data[0]).some(key => key === col)
      );

      if (!hasRequiredColumns) {
        throw new Error('Gerekli sütunlar eksik: Cari hesap adı, Ciro');
      }

      const filteredData = data.filter(row => 
        row['Cari hesap adı'] && 
        row['Cari hesap adı'].trim() !== '' &&
        row['Ciro'] !== undefined
      );

      if (filteredData.length === 0) {
        throw new Error('Dosyada geçerli veri bulunamadı');
      }

      return filteredData;
    } catch (err) {
      throw new Error('Veri işlenirken hata oluştu: ' + err.message);
    }
  }, []);

  const saveDataToFirestore = async (file, processedData) => {
    try {
      setUploadStatus('uploading');
      setUploadProgress(20);
      setError('');

      // Prepare the data to save
      const fileData = {
        fileName: file.name,
        uploadedAt: serverTimestamp(),
        userId: currentUser.uid,
        userEmail: currentUser.email,
        fileSize: file.size,
        contentType: file.type,
        data: processedData,
        fileType: file.name.split('.').pop().toLowerCase()
      };

      setUploadProgress(50);

      // Save to Firestore
      await addDoc(collection(fireStore, 'files'), fileData);

      setUploadStatus('success');
      setUploadProgress(100);
      
      // Set the data for display
      setRevenueData(processedData);
      
      // Reset status after a delay
      setTimeout(() => {
        setUploadStatus('');
        setUploadProgress(0);
      }, 3000);

      return processedData;
    } catch (error) {
      console.error('Error saving data:', error);
      setError('Veri kaydedilirken bir hata oluştu');
      setUploadStatus('error');
      setUploadProgress(0);
      throw error;
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setIsUploading(true);
    setError('');
    setSelectedFile(file);
    setUploadProgress(0);

    try {
      validateFile(file);
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const reader = new FileReader();

      reader.onload = async (e) => {
        try {
          setUploadStatus('Veri işleniyor...');
          const fileData = e.target.result;
          
          if (fileExtension === 'csv') {
            Papa.parse(fileData, {
              header: true,
              complete: async (results) => {
                try {
                  const processedData = processData(results.data);
                  await saveDataToFirestore(file, processedData);
                } catch (error) {
                  setError(error.message);
                } finally {
                  setIsUploading(false);
                  setSelectedFile(null);
                }
              },
              error: (error) => {
                setError('CSV dosyası okunurken hata oluştu: ' + error.message);
                setIsUploading(false);
                setSelectedFile(null);
              }
            });
          } else if (['xls', 'xlsx'].includes(fileExtension)) {
            try {
              const workbook = XLSX.read(fileData, { type: 'array' });
              const sheetName = workbook.SheetNames[0];
              const worksheet = workbook.Sheets[sheetName];
              const jsonData = XLSX.utils.sheet_to_json(worksheet);
              
              const processedData = processData(jsonData);
              await saveDataToFirestore(file, processedData);
            } catch (error) {
              setError(error.message);
            } finally {
              setIsUploading(false);
              setSelectedFile(null);
            }
          }
        } catch (error) {
          setError(error.message);
          setIsUploading(false);
          setSelectedFile(null);
        }
      };

      reader.onerror = () => {
        setError('Dosya okuma hatası');
        setIsUploading(false);
        setSelectedFile(null);
      };

      if (fileExtension === 'csv') {
        reader.readAsText(file);
      } else {
        reader.readAsArrayBuffer(file);
      }
    } catch (error) {
      setError(error.message);
      setIsUploading(false);
      setSelectedFile(null);
    }
  };

  const handleFileSelect = (file) => {
    if (file?.data) {
      setRevenueData(file.data);
    }
  };

  const checkForExistingFiles = (filesExist) => {
    setHasUploadedFiles(filesExist);
  };

  return (
    <Container fluid>
      <DashboardLayout>
        <HeaderSection>
          <UserInfo>
            <Header as="h1">Yönetici Paneli</Header>
            <p>Kullanıcı: {currentUser ? currentUser.email : ''}</p>
          </UserInfo>
          <Button primary onClick={handleLogout} size="small">
            Çıkış Yap
          </Button>
        </HeaderSection>
        
        <MainContent>
          <SidebarColumn>
            <Header as="h2">Ciro Raporu Yükle</Header>
            <Form error={!!error}>
              <CustomFileInput>
                <input 
                  type="file" 
                  id="fileInput" 
                  accept=".csv, .xls, .xlsx"
                  onChange={handleFileUpload}
                  disabled={isUploading}
                />
                <label htmlFor="fileInput">Rapor Yükle</label>
                {!hasUploadedFiles && !selectedFile && (
                  <span>Dosya seçilmedi</span>
                )}
                {selectedFile && (
                  <span>{selectedFile.name}</span>
                )}
              </CustomFileInput>
              <p style={{ fontSize: '0.8em', color: '#666', marginTop: '5px' }}>
                Desteklenen formatlar: CSV, XLS, XLSX
              </p>
              
              {error && (
                <Message
                  error
                  header="Hata"
                  content={error}
                  size="small"
                />
              )}

              {uploadStatus && (
                <UploadProgress 
                  status={uploadStatus}
                  progress={uploadProgress}
                />
              )}
            </Form>
            
            <FileList 
              currentUser={currentUser} 
              onFileSelect={handleFileSelect}
              onFilesLoaded={checkForExistingFiles}
            />
          </SidebarColumn>
          
          <ContentColumn>
            {revenueData ? (
              <RevenueChart data={revenueData} />
            ) : (
              <Message info size="large" style={{ marginTop: '2em' }}>
                <Message.Header>Grafik Görüntülemek İçin Dosya Seçin</Message.Header>
                <p>Sol panelden bir dosya seçin veya yeni bir dosya yükleyin.</p>
              </Message>
            )}
          </ContentColumn>
        </MainContent>
      </DashboardLayout>
    </Container>
  );
} 